import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _45082037 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _077bb8e2 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _103b92e8 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _45e7884b = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _50924b98 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _90ca9348 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _263e1f2a = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _74c7f9d3 = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _55005b28 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _5a000672 = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _3cc8318c = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _7eb32a52 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _1582dd94 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _57b991ce = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _56445618 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _7b3f7a1a = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _56d50686 = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _5ecff3b3 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _ce63ac06 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _30c88664 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _45082037,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _077bb8e2,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _103b92e8,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _45e7884b,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _50924b98,
    name: "receipt"
  }, {
    path: "/search",
    component: _90ca9348,
    name: "search"
  }, {
    path: "/search-booking",
    component: _263e1f2a,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _74c7f9d3,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _55005b28,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _5a000672,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _3cc8318c,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _7eb32a52,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _1582dd94,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _57b991ce,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _56445618,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _7b3f7a1a,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _56d50686,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _5ecff3b3,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _ce63ac06,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _30c88664,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
